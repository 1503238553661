//
// _helper.scss
//
.fw-medium {
    font-weight: $fw-medium;
}

.section {
    padding: 100px 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-white {
    background-color: $bg-overlay-white;
}

//Title
.title-heading {
    line-height: 26px;
    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
        @media (max-width: 768px) {
            font-size: 35px !important;
        }
    }
    .para-desc {
        font-size: 18px;
    }
}
.section-title {
    // position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 30px !important;
        
        @media (max-width: 768px) {
            font-size: 24px !important;
        }
    }
} 

.para-desc {
    max-width: 600px;
}
.mt-100 {
    margin-top: 100px;
}
//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }

    &.integration-hero {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        height: 250px;
        background: $white;

        @media (max-width: 768px) {
            height: 140px;
        }

        @media (max-width: 425px) {
            height: 60px;
        }
    }
    
    @media (max-width: 425px) {
        bottom: -4px;
    }
}
  
@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}
@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }   
}

/*********************************/
/*         Timeline              */
/*===============================*/
//Timeline
.main-icon {
    border: 2px solid $border-color;
    height: 40px;
    width: 40px;
    line-height: 0;
    text-align: center;
    .fea {
        height: 20px;
    }
    
    @media (min-width: 768px) {
        margin: 0 auto;        
    }
}
.timeline-page {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 95%;
        margin: auto;
        width: 2px;
        background-color: $gray-200 !important;
    }
    .timeline-item {
        .date-label-left, .duration-right {
            margin-right: 15px;
            &:after {
                content: "";
                position: absolute;
                top: 12px;
                width: 12px;
                height: 12px;
                z-index: 1;
                background-color: $primary;
                border-radius: 4px;
                transform: rotate(45deg);
            }
        }
        .date-label-left {            
            float: right;
            margin-right: 15px;
            &:after {                
                right: -34px;
            }
        }        
        .duration-right {
            float: left;
            margin-left: 15px;
            &:after {                
                left: -34px;
            }
        }
        .event {
            transition: all 0.2s ease;
            &.event-description-right {
                margin-left: 15px;
            }
            &.event-description-left {
                margin-right: 15px;
                text-align: right;
            }
        }
    }
}


@media (max-width: 767px) {
    .timeline-page {
        margin-left: 18px;
        &:after {
            margin: 0;
        }
        .timeline-item {
            .duration {
                float: left !important;
                margin: 0 0 30px 15px !important;
                text-align: left !important;
                &:after {
                    left: -21px !important;
                }
                .event {
                    text-align: left !important;
                    margin-left: 15px;
                }
            }
            .event-description-left {
                text-align: left !important;
                margin: 0 0 0 15px;
            }
        }
    }
}



//back-to-home
.back-to-home {
    position: fixed;
    top: 4%;
    right: 2%;
    z-index: 1;
}

//Sticky Sidebar
.sticky-bar {
    position: sticky;
    top: 80px;
}

//Feather Icon
.fea {
    stroke-width: 1.8;
    &.icon-sm {
        height: 16px;
        width: 16px;
    }
    &.icon-ex-md {
        height: 20px;
        width: 20px;
    }
    &.icon-m-md {
        height: 28px;
        width: 28px;
    }
    &.icon-md {
        height: 35px;
        width: 35px;
    }
    &.icon-lg {
        height: 42px;
        width: 42px;
    }
    &.icon-ex-lg {
        height: 52px;
        width: 52px;
    }
}

[class^=uil-], 
[class*=" uil-"] {
    &:before {
        margin: 0;
    }
}

.uim-svg {
    vertical-align: 0 !important;
}



//Social icons
.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            height: 32px;
            width: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            .fea-social {
                stroke-width: 2;
            }
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $foot-social-color-white !important;
                .fea-social {
                    fill: $primary;
                }
            }
        }
    }
    &.social {
        li {
            a {
                color: $foot-social-color;
                border-color: $social-border-color;
            }
        }
    }

    &.foot-social-icon {
        li {
            a {
                color: $foot-social-color;
                border-color: lighten($footer, 5%);
            }
        }
    }
}

// Rounded
.rounded {
    border-radius: 6px !important;
}
.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
@each $name,
$value in $theme-colors {
    .border-#{$name} {
        border-color: #{$value} !important;
    }
}


.bg-white-color {
    background: $bg-white-color;
}

.d-flex {
    .flex-1 {
        flex: 1;
    }
}