//
// work.scss
//

/*********************************/
/*         Works & Portfolios    */
/*===============================*/

.work-container {
    &.work-classic {
        .work-image {
            transition: all 0.5s ease;
            &:hover {
                box-shadow: $shadow;
            }
        }
    }
    &.work-classic,
    &.work-modern {
        .content {
            .title {
                font-size: 18px;
                transition: all 0.5s ease;
                &:hover {
                    color: $primary !important;
                }
            }
            .tag {
                font-size: 15px !important;
            }
        }
    }
    &.work-modern {
        img {
            transition: all 0.5s ease;
        }
        &:hover {
            img {
                transform: scale(1.1) rotate(3deg);
            }
        }
    }
}

//Tobii Lighbox
.tobii>button.tobii__close svg,
.tobii>button.tobii__prev svg, 
.tobii>button.tobii__next svg {
    height: 36px;
    width: auto;
}

.tobii__counter {
    font-size: 16px;
}

.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: 0 !important;
}