//
// home.scss
//

/*********************************/
/*         Home & Hero           */
/*===============================*/

@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}
.bg-home {
    height: 100vh;    
    @include home-common();
    @media (max-width: 767px) {
        padding: 150px 0;
        height: auto;
    }
}

//Gradient Home
.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba($primary, 0.1) 0%, 
                                                   rgba($primary, 0.1) 33.333%,
                                                   rgba($primary, 0.2) 33.333%, 
                                                   rgba($primary, 0.2) 66.666%,
                                                   rgba($primary, 0.3) 66.666%, 
                                                   rgba($primary, 0.3) 99.999%);
}