//
// user.scss
//

/*********************************/
/*         Authentications       */
/*===============================*/

.login-page {
    font-size: 15px;
    .forgot-pass {
        z-index: 99;
        position: relative;
        font-size: 13px;
    }
} 

.cover-user {
    .container-fluid{
        max-width: 100%;
        .padding-less{

            @media (max-width: 768px) {
                position: relative !important;
                height: 400px !important;
            }

            &.img {
                background: scroll center no-repeat;
                background-size: cover;
                position: absolute;
                height: 100%;
            }
        }
    }    
    .cover-user-img {
        height: 100vh;

        @media (min-width: 1025px) {
            padding: 0px 20%;
        }
        
        @media (max-width: 768px) {
            align-items: start !important;
            height: auto !important;
            padding: 30px;
        }
    }

    
    @media (max-width: 768px) {
        .cover-user-content {
            text-align: left !important;
        }    
        .cover-my-30 {
            margin: 30px 0;
        }
    }
}

.form-signin {
    width: 100%;
    max-width: 400px;
    margin: auto;
}

//Profile
.avatar-profile {
    position: relative;
    top: -80px;
}