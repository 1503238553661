//
// _chat.scss
//

.chat {
    .chat-list {
        &:hover,
        &.active,
        &:focus {
            background: $light !important;
            box-shadow: $shadow;
        }
    }
    
    .chat-list,
    .chat-type {
        .on-off {
            position: absolute;
            left: -1px;
            top: -1px;
            font-size: 12px !important;
        }
    }
    
    &.chat-person {
        .on-off {
            font-size: 10px;
        }
        .chat-msg {
            margin-left: 8px;
            .msg {
                background: $body-bg;
            }
            .msg-time {
                font-size: 12px !important;
            }
        }
        .chat-right {
            justify-content: flex-end;
            text-align: right;
            .chat-user-image {
                order: 2;
                .on-off {
                    right: -1px;
                }
            }
            .chat-msg {
                margin-right: 8px;
                margin-left: 0;
                .msg {
                    display: inline-block;
                }
                .msg-time {
                    display: block;
                }
            }
        }
    }
}

//Ck editor
.ck.ck-toolbar,
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    background: $body-bg !important;
    border: 1px solid $border-color !important;
}

.ck.ck-toolbar>.ck-toolbar__items>:not(.ck-toolbar__line-break), .ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown {
    color: $body-color;
}