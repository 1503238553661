//
// price.scss
//

/*********************************/
/*         Pricing & Plans       */
/*===============================*/

.pricing-rates {
    position: relative;
    z-index: 1;
    border-bottom: 3px solid $border-color !important;
    transition: all 0.5s ease;
    .price {
        font-weight: 400;
    }
    &.business-rate {
        &:hover {
            transform: scale(1.03);
            z-index: 2;
            border-color: $primary !important;
            box-shadow: $shadow-md !important;
            background-color: $pricing-bg !important;
            .title {
                color: $primary;
            }
        }
    }
}

.ribbon {
    @each $name,
    $value in $theme-colors {
        &.ribbon-#{$name} {
            span {
                background-color: #{$value} !important;
                &:before {
                    border-left-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
        
                &:after {
                    border-right-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    top: -5px;
    width: 75px;
    height: 75px;
    z-index: 2;
    &.ribbon-right {
        right: -5px;
        span {
            right: -23px;
            transform: rotate(45deg);
        }
    }
    &.ribbon-left {
        left: -5px;
        span {
            left: -23px;
            transform: rotate(-45deg);
        }
    }
    span {
        position: absolute;
        top: 18px;
        width: 100px;
        color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            border: 3px solid transparent;
        }
        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

//page-invoice.html
.invoice-top {
    .logo-invoice {
        font-weight: 700;
        font-size: 27px;
        letter-spacing: 1px;
    }
}

//Switcher-pricing
.switcher-pricing {
    .toggle, .toggler {
        display: inline-block !important;
        vertical-align: middle;
    }
    
    .toggler {
        transition: all 0.5s ease;
        font-weight: bold;
    }
    
    .toggler--is-active {
        color: $primary !important;
    }

    .form-switch {
        .form-check-input {
            width: 48px;
            height: 24px;
            margin-top: 0;
        }
    }
}

.hide{
    display: none;
}

//Apexcharts
.apexcharts-legend-text {
    font-family: $font-family-base !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $apexchart-color !important;
}

.apexcharts-tooltip {
    background: $apexchart-tool-bg !important;
    color: $apexchart-color;
    border-color: $border-color !important;
}

.apexcharts-gridlines-horizontal,
.apexcharts-gridlines-vertical,
.apexcharts-grid,
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line,
.apexcharts-gridline line,
.apexcharts-radar-series line,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, 
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, 
.apexcharts-radar-series path, 
.apexcharts-radar-series polygon{
  stroke: $apex-border-color !important;
}

.apexcharts-data-labels, .apexcharts-datalabels text, .apexcharts-xaxis text, .apexcharts-yaxis text {
    font-family: $font-family-base !important;
    fill: $apexchart-color !important;
}
.apexcharts-tooltip-title{
    background: $apexchart-tool-bg !important;
    color: $apexchart-color !important;
    border-color: $border-color !important;
}
